/*eslint-disable */
<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle v-if="!propOpenedInModal">
            <h4 class="card-title">{{ cvCardTitle }}</h4>
          </template>
          <template v-slot:body>
            <p v-if="!propOpenedInModal">{{ cvCardSubHeader }}</p>
            <ValidationObserver ref="observer">
            <form action="#">
              <div class="form-row" >
                <div class="col-md-12 mb-3" v-if="userData.user_role == 'USERROLE11111'">
                  <label for="validationcounsellor_id">
                    {{cvCounsellorsList}}</label
                  >
                  <ValidationProvider name="counsellor name" rules="required" v-slot="{ errors }" ref="counsellorAdd">
                  <multiselect
                    v-model="vmSpcmCalendarFormData.counsellor_id"
                    :tag-placeholder="cvSelectLabel"
                    :placeholder="cvSelectLabel"
                    :searchable="true"
                    class="multiselect_group"
                    label="user_name"
                    track-by="user_name"
                    :options="counsellorObjList"
                    >
                  </multiselect>
                  <span style="color:red">{{ errors[0] }}</span>
                </ValidationProvider>

              </div>
               <div class="col-md-12 mb-3" v-else>
                <ValidationProvider name="counsellor name" rules="required" v-slot="{ errors }" ref="counsellorAdd">
                  <label for="validationcounsellor_id">
                    {{ cvCounsellorLabel }}</label
                  >
                  <p>{{ userData.user_name }}</p>
                </ValidationProvider>
              </div>
              <div class="col-md-12 mb-3">
                  <label for="validationcounsellor_id">
                    {{cvDateRange}}</label
                  >
                  <div class="dateRange">
                    <date-range-picker ref="picker" :show-dropdowns="true" class="w-100"  :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"  v-model="whereFilter.dateRange"></date-range-picker>
                </div>
              </div>
              <div class="col-md-12 mb-3">
                  <label for="">{{ cvSessionDays }}</label>
                <b-form-checkbox-group
                  id="checkbox-group-1"
                  v-model="selectedDays"
                  :options="weekDays"
                  name="flavour-1"
                  ></b-form-checkbox-group>
                  <span>{{ weekDaysArrayIsEmpty ? 'Select atleast a day' : '' }}</span>
              </div>
              <div class="col-md-6 mb-3">
                  <label for="validationslot_start_ts">
                    {{cvSlotStartTimeLabel}}</label
                  >
                  <ValidationProvider rules="required" v-slot="{ errors }">
                  <datetime
                    type="time"
                    v-model="vmSpcmCalendarFormData.slot_start_ts"
                    input-class="datetime_input gide_datetime"
                    class="form-control"
                    :format="{ hour: 'numeric', minute: '2-digit'}"
                    :phrases="{ok: 'Continue', cancel: 'Exit'}"
                    :hour-step="1"
                    :minute-step="5"
                    :week-start="7"
                    use12-hour
                    auto
                    :min-datetime="minStartDate"
                  ></datetime>
                  <span>{{ errors[0] ? 'Time slot cannot be empty' : '' }}</span>
                </ValidationProvider>
              </div>
              <!-- <div class="form-group">

              </div> -->
                <div class="col-6 mb-3">
                  <label for="validationslot_duration">
                    {{cvSlotDurationLabel}}</label
                  >
                  <input
                    v-model="vmSpcmCalendarFormData.slot_duration"
                    type="text"
                    class="form-control"
                    required
                  />
              </div>
              </div>

              <div class="form-group">
                <button v-if="cvLoadingStatus" type="button" class="btn btn-primary" :disabled="cvLoadingStatus">
                   <b-spinner label="Spinning"></b-spinner>
                </button>
                <button
                  type="button"
                  class="btn btn-primary"
                  @click="spcmCalendarAdd()"
                >
                  {{ cvSubmitBtn }}
                </button>
              </div>
            </form>
          </ValidationObserver>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-toast
      v-model="showToast"
      name="Toast"
      :auto-hide-delay="toastVariant === 'danger' ? 10000 : 1000"
      :variant="toastVariant"
      :title="toastTitle"
    >
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
 .datetime_input {
    border: 1px solid #eeeeee;
    border-radius: 10px;
    height: 45px;
    cursor: pointer;
    padding-left: 10px;
    color: #a09e9e;
    width: 100%;
  }
  .theme-primary .vdatetime-popup__header,
  .theme-primary .vdatetime-calendar__month__day--selected > span > span,
  .theme-primary .vdatetime-calendar__month__day--selected:hover > span > span {
    background: #000000;
  }

  .theme-primary .vdatetime-year-picker__item--selected,
  .theme-primary .vdatetime-time-picker__item--selected,
  .theme-primary .vdatetime-popup__actions__button {
    color: #000000;
  }
  label{
    text-transform: capitalize;
  }
</style>
<script>
import { SpcmCalenders } from "../../../FackApi/api/SpcmCalender"
import ApiResponse from "../../../Utils/apiResponse.js"
import Multiselect from "vue-multiselect"
import "vue-datetime/dist/vue-datetime.css"
import { Datetime } from "vue-datetime"
import { User } from "../../../FackApi/api/user"
import { ValidationProvider, ValidationObserver } from "vee-validate"
// const { Validator } = require("vee-validate")
import userPermission from "../../../Utils/user_permission.js"
import { SpcmCounsellors } from "../../../FackApi/api/SpcmCounsellor.js"

export default {
  name: "SpcmCalenderAdd",
  props: {
    propOpenedInModal: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Datetime,
    Multiselect,
    ValidationProvider,
    ValidationObserver
  },
  data () {
    return {
      apiName: "spcm_calendar_add",
      cvCardTitle: "Add Spcm Calendar",
      cvCardSubHeader: "Add Spcm Calendar ",
      weekDaysArrayIsEmpty: false,
      cvCounsellorLabel: "Counsellor",
      cvSubmitBtn: "Add",
      cvSelectLabel: "Counselor Name",
      cvSessionDays: "Session for days",
      cvCounsellorIdLabel: "counsellor id",
      cvCounsellorsList: "counsellor name",
      // cvSlotStartTsLabel: "slot start ts",
      cvSlotDate: "Date",
      cvDateRange: "Select Date Range",
      minStartDate: "",
      cvSlotDurationLabel: "slot duration (in sec)",
      cvSlotStartTimeLabel: "time",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      cvLoadingStatus: false,
      toastTitle: "SpcmCalender Add Response",
      vmSpcmCalendarFormData: Object.assign({}, this.initFormData()),
      vmSpcmCalenderDesc: null,
      vmSpcmCalenderType: null,
      cvCounsellorOptions: [],
      counsellorObjList: [],
      counsellor_id: {},
      vmStartDate: "",
      whereFilter: {
        dateRange: {
          startDate: new Date(new Date().setDate(new Date().getDate() - 365)), // From Last Year
          endDate: new Date(new Date().setDate(new Date().getDate() + 1))
        }
      },
      weekDays: [
        { text: "Monday", value: "MON" },
        { text: "Tuesday", value: "TUE" },
        { text: "Wednesday", value: "WED" },
        { text: "Thursday", value: "THU" },
        { text: "Friday", value: "FRI" },
        { text: "Saturday", value: "SAT" },
        { text: "Sunday", value: "SUN" }
      ],
      selectedDays: []
    }
  },
  computed: {
    userData () {
      return { ...this.$store.getters["User/userData"] }
    }
  },
  beforeMount () {
    // Auth Access for Current User Role
    if (!userPermission(this, this.userData, this.apiName)) {
      this.$router.back()
    }
  },
  mounted () {
    this.spcmCounsellorList()
    this.getCounsellorList()
  },
  methods: {
    /**
     * initFormData
     */
    initFormData () {
      return {
        "counsellor_id": "",
        "slot_start_ts": "",
        "slot_duration": "3600"
      }
    },
    /**
     * validate
     */
    validate () {
      try {
        for (let index in this.vmSpcmCalendarFormData) {
          if (!this.vmSpcmCalendarFormData[index]) {
            this.toastMsg = "Missing " + index.split("_").join(" ")
            this.toastVariant = "danger"
            this.showToast = true
            return false
          }
        }
        return true
      }
      catch (err) {
        console.error("Exception occurred at validate() and Exception:", err.message)
      }
    },
    /**
     * spcmCalendarAdd
     */
    async spcmCalendarAdd () {
      // if (!this.validate()) {
      //   return false
      // }
      if (this.userData.user_role == "USERROLE11118") {
        this.vmSpcmCalendarFormData.counsellor_id = this.userData.user_id
      }
      else {
        const isValid = await this.$refs.counsellorAdd.validate()
        if (!isValid.valid) {
          return
        }

        this.$refs.observer.validate().then((success) => {
          if (success) {
            console.error("FORM INVALID")
          }
          else {
            console.error("FORM VALID")
          }
        })
      }

      if (this.selectedDays.length == 0) {
        this.weekDaysArrayIsEmpty = true
      }
      else {
        this.weekDaysArrayIsEmpty = false
      }
      try {
        this.cvLoadingStatus = true
        this.vmSpcmCalendarFormData.counsellor_id = this.vmSpcmCalendarFormData.counsellor_id.counsellor_user_id ? this.vmSpcmCalendarFormData.counsellor_id.counsellor_user_id : this.userData.user_id
        let payload = {
          counsellor_id: this.vmSpcmCalendarFormData.counsellor_id,
          slot_duration: this.vmSpcmCalendarFormData.slot_duration,
          slot_start_ts: this.vmSpcmCalendarFormData.slot_start_ts,
          date_range: [this.whereFilter.dateRange.startDate, this.whereFilter.dateRange.endDate],
          selected_week_days: this.selectedDays
        }
        let spcmcalendarAddResp = await SpcmCalenders.spcmCalendarAdd(this, payload)
        await ApiResponse.responseMessageDisplay(this, spcmcalendarAddResp)
        if (spcmcalendarAddResp && !spcmcalendarAddResp.resp_status) {
          return false
        }
        this.$emit("emitAddsessions", spcmcalendarAddResp.resp_data)
        //  Emit if opened in Modal
        if (this.propOpenedInModal) {
          this.$emit("emitCloseSpcmCalenderAddModal")
        }
        this.vmSpcmCalendarFormData = Object.assign({}, this.initFormData())
      }
      catch (err) {
        console.error("Exception occurred at spcmcalendarAdd() and Exception:", err.message)
      }
      finally {
        this.cvLoadingStatus = false
      }
    },
    /**
     * getCounsellorList
     */
    async getCounsellorList () {
      const payload = {
        user_role: "USERROLE11118" // counsellor
      }
      const apiResp = await User.userListSearch(this, payload)
      if (apiResp.resp_status) {
        this.cvCounsellorOptions = apiResp.resp_data.data
      }
    },
    /**
     * setCounsellorListFilters
    */
    async spcmCounsellorList () {
      const counselorsListResp = await SpcmCounsellors.spcmCounsellorList()
      if (counselorsListResp.resp_status) {
        this.counsellorObjList = counselorsListResp.resp_data.data
      }
    }
  }
}
</script>
