/*eslint-disable */
// import SpcmCounsellorModel from '../../Model/SpcmCounsellor'
import request from '../../Utils/curl'
import Utility from "../../Utils/utility";

let SpcmCounsellors = {
  /**
   * spcmCounsellorList
   */
  async spcmCounsellorList (context, whereFilter=null) {
    try{
      Utility.showLoader = true;
      Utility.loadingMsg = "Please wait....";

      let post_data = new FormData();  
      if (whereFilter) {
        post_data.append('filter', JSON.stringify(whereFilter))
      }

      return await request.curl(context, "spcm_counsellor_list", post_data)
        .then(async response => {
          return response
        });

    }
    catch(err){
      console.error('Exception occurred at spcmCounsellorList() and Exception:',err.message)
    }
    finally{
      Utility.showLoader = false;
    }
  },

  /**
   * spcmCounsellorView
   */
  async spcmCounsellorView (context, spcmCounselorId) {
    try {      
      let post_data = new FormData();
      post_data.append('spcm_counselor_id', spcmCounselorId);
      return await request.curl(context, "spcm_counsellor_view", post_data)
      .then(async response => {
        return response;
      });
    } 
    catch (err) {
      console.error("Exception occurred at spcmCounsellorView() and Exception:",err.message)
    }    
  },

  /**
   * spcmCounsellorAdd
   */
  async spcmCounsellorAdd (context, spcmCounsellorObj) {
    try{
      let post_data = new FormData();
    
      for (let key in spcmCounsellorObj) {
        post_data.append(key, spcmCounsellorObj[key]);
      }

      return await request.curl(context, "spcm_counsellor_add", post_data)
      .then(async response => {
          return response;
      });
    }
    catch(err){
      console.error('Exception occurred at spcmCounsellorAdd() and Exception:',err.message)
    }
  },

  /**
   * spcmCounsellorEdit
   */
  async spcmCounsellorEdit (context, spcmCounsellorObj) {
    try{
      let post_data = new FormData();
    
      for (let key in spcmCounsellorObj) {
        post_data.append(key, spcmCounsellorObj[key]);
      }

      return await request.curl(context, "spcm_counsellor_edit", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at spcmCounsellorEdit() and Exception:',err.message)
    }
  },

  /**
   * spcmCounsellorDelete
   */
  async spcmCounsellorDelete (context, spcmCounselorId) {
    try{
      let post_data = new FormData();
      
      post_data.append('spcm_counselor_id', spcmCounselorId);

      return await request.curl(context, "spcm_counsellor_delete", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.log('Exception occurred at spcmCounsellorDelete() and Exception:',err.message)
    }
  },

  /**
   * spcmStudentTeamCounselorRoleEdit
   */
  async spcmStudentTeamCounselorRoleEdit (context, spcmStudentTeamObj) {
    try{
      let post_data = new FormData();
    
      for (let key in spcmStudentTeamObj) {
        post_data.append(key, spcmStudentTeamObj[key]);
      }

      return await request.curl(context, "spcm_student_team_counselor_role_edit", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at spcmStudentTeamCounselorRoleEdit() and Exception:',err.message)
    }
  }  
}

export {
  SpcmCounsellors
}
